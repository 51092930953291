body {
  margin: 0;
  font-family: 'JetBrains Mono', 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scrollbar-color: #4A88C7 #595B5D;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 20px;
}

*::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #a3a5ba;
  /* background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%); */
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}

@tailwind base;
@tailwind components;
@tailwind utilities;